
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class ForgetPass extends SuperVue {
  created() {
    this.devicePixelRatio = window.devicePixelRatio
    const that = this
    window.addEventListener('resize', function() {
      that.devicePixelRatio = window.devicePixelRatio
    })
  }
  devicePixelRatio = 0
  activeName = 'first'
  codeBtn = {
    disable: false,
    text: '发送验证码'
  }
  codeBtn1 = {
    mailTip: false,
    disable: false,
    text: '发送邮件'
  }
  formPageOne = formPageOne
  formPageTwo = formPageTwo
  rulePageOne = rulePageOne
  rulePageTwo = rulePageTwo
  modal1 = {
    open: false
  }
  modal2 = {
    open: false
  }
  // 发送验证码
  sendCode() {
    const testphone = /^1[3456789]\d{9}$/.test(this.formPageOne.item2)
    if (!testphone) {
      this.$message.error('手机号不正确')
      return
    }
    if (this.codeBtn.disable) return
    const _this = this
    Api.http_sendCode({ phone: this.formPageOne.item2 }).then((res) => {
      console.log(res)
      if (res.code !== '200') {
        this.$message.error(res.message) // 接口出错
      } else {
        this.$message.success(res.message)
        let timer = 60
        _this.codeBtn.disable = true
        _this.codeBtn.text = timer + 's'
        const timeStop = setInterval(function () {
          timer--
          if (timer >= 0) {
            _this.codeBtn.text = timer + 's'
          } else {
            timer = 60 // 当减到0时赋值为60
            _this.codeBtn.text = '获取验证码'
            _this.codeBtn.disable = false
            clearInterval(timeStop) // 清除定时器
          }
        }, 1000)
      }
    })
  }
  updatePassByPhone() {
    this.$refs['formPageOneRef'].validate((valid) => {
      if (valid) {
        const postData = {
          code: this.formPageOne.item3,
          phone: this.formPageOne.item2,
          password: this.formPageOne.item4
        }
        Api.http_updatePassByCode(postData).then((res) => {
          this.modal1.open = false
          if (res.code !== '200') {
            this.$message.error(res.message) // 接口出错
          } else {
            this.$message.success(res.message)
            this.$message.success('即将跳转登录页!')

            setTimeout(() => {
              this.$router.push({ name: 'login' })
            }, 1000)
          }
        })
      }
    })
  }
  getMailCode() {
    const testphone
      = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
        this.formPageTwo.item2
      )
    if (!testphone) {
      this.$message.error('邮箱不正确')
      return
    }
    if (this.codeBtn1.disable) return
    const _this = this
    let timer = 60
    _this.codeBtn1.disable = true
    _this.codeBtn1.text = timer + 's'
    const timeStop = setInterval(function () {
      timer--
      if (timer >= 0) {
        _this.codeBtn1.text = timer + 's'
      } else {
        timer = 60 // 当减到0时赋值为60
        _this.codeBtn1.text = '获取验证码'
        _this.codeBtn1.disable = false
        clearInterval(timeStop) // 清除定时器
      }
    }, 1000)
    Api.http_sendEmailCode({ email: this.formPageTwo.item2 }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message) // 接口出错
      } else {
        this.codeBtn1.mailTip = true
        this.$message.success(res.message)
      }
    })
  }
  updatePassByMail() {
    this.$refs['formPageTwoRef'].validate((valid) => {
      if (valid) {
        const postData = {
          code: this.formPageTwo.item3,
          email: this.formPageTwo.item2,
          password: this.formPageTwo.item4
        }
        Api.http_updatePassByCode(postData).then((res) => {
          this.modal2.open = false
          if (res.code !== '200') {
            this.$message.error(res.message) // 接口出错
          } else {
            this.$message.success(res.message)
            this.$message.success('即将跳转登录页!')

            setTimeout(() => {
              this.$router.push({ name: 'login' })
            }, 1000)
          }
        })
      }
    })
  }
  // 链接跳转
  toLink(name: string) {
    this.$router.push({ name: name })
  }
}

const formPageOne = {
  item2: '',
  item2_config: {
    label: '手机号',
    placeholder: '请输入手机号码'
  },
  item3: '',
  item3_config: {
    label: '手机验证码',
    placeholder: '请输入验证码'
  },
  item4: '',
  item4_config: {
    label: '密码',
    placeholder: '请输入新密码',
    showPass: false
  },
  item5: '',
  item5_config: {
    label: '确认密码',
    showPass: false,
    placeholder: '请再次输入新密码'
  }
}
const formPageTwo = {
  item2: '',
  item2_config: {
    label: '邮箱',
    placeholder: '请输入邮箱'
  },
  item3: '',
  item3_config: {
    label: '验证码',
    placeholder: '请输入验证码'
  },
  item4: '',
  item4_config: {
    label: '密码',
    showPass: false,
    placeholder: '请输入新密码'
  },
  item5: '',
  item5_config: {
    label: '确认密码',
    showPass: false,
    placeholder: '请再次输入新密码'
  }
}
const validatePassCheck = function (rule, value, callback) {
  if (value === '') {
    return callback(new Error('请再次输入密码!'))
  } else if (value !== formPageOne.item4) {
    return callback(new Error('两次密码不一致'))
  } else {
    callback()
  }
}
const validatePassCheck2 = function (rule, value, callback) {
  if (value === '') {
    return callback(new Error('请再次输入密码!'))
  } else if (value !== formPageTwo.item4) {
    return callback(new Error('两次密码不一致'))
  } else {
    callback()
  }
}
const rulePageOne = {
  item2: [
    { required: true, message: '请输入手机号！', trigger: 'blur' },
    {
      pattern: /^1[3456789]\d{9}$/,
      message: '手机号码格式不正确',
      trigger: 'blur'
    }
  ],
  item3: [
    { required: true, message: '验证码不能为空！', trigger: 'blur' },
    { type: 'string', max: 6, message: '验证码最多6字符！', trigger: 'blur' }
  ],
  item4: [
    { required: true, message: '请输入密码！', trigger: 'blur' },
    { type: 'string', min: 6, message: '密码最少6字符！', trigger: 'blur' }
  ],
  item5: [
    { required: true, message: '请再次输入密码！', trigger: 'blur' },
    { validator: validatePassCheck, trigger: 'blur' }
  ]
}

const rulePageTwo = {
  item2: [
    {
      required: true,
      type: 'email',
      message: '邮箱格式不正确',
      trigger: 'blur'
    }
  ],
  item3: [
    { required: true, message: '验证码不能为空！', trigger: 'blur' },
    { type: 'string', max: 6, message: '验证码最多6字符！', trigger: 'blur' }
  ],
  item4: [
    { required: true, message: '请输入密码！', trigger: 'blur' },
    { type: 'string', min: 6, message: '密码最少6字符！', trigger: 'blur' }
  ],
  item5: [
    { required: true, message: '请再次输入密码！', trigger: 'blur' },
    { validator: validatePassCheck2, trigger: 'blur' }
  ]
}
